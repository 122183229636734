// extracted by mini-css-extract-plugin
export var blogCategory = "BlogCategory__blogCategory__QjYJN";
export var blogCategoryContainer = "BlogCategory__blogCategoryContainer__uojF_";
export var categoryIntro = "BlogCategory__categoryIntro__W_96E";
export var column = "BlogCategory__column__o313R";
export var container = "BlogCategory__container__l7eNf";
export var current = "BlogCategory__current__ROSaw";
export var flex = "BlogCategory__flex__Ur225";
export var flexColumn = "BlogCategory__flexColumn__m6wsJ";
export var gap1 = "BlogCategory__gap1__W049S";
export var gap2 = "BlogCategory__gap2__CGS6w";
export var gap3 = "BlogCategory__gap3__alaHy";
export var gap4 = "BlogCategory__gap4__xSA8Y";
export var gap5 = "BlogCategory__gap5__Z_FE3";
export var link = "BlogCategory__link__zhSDO";
export var pagination = "BlogCategory__pagination__QR2zu";
export var row = "BlogCategory__row__MgHMH";
export var sidebarContainer = "BlogCategory__sidebarContainer__t1Z6B";
export var spinnerContainer = "BlogCategory__spinnerContainer__krpbo";
export var topCategories = "BlogCategory__topCategories__Bq_Re";
export var topContainer = "BlogCategory__topContainer__tCcpI";