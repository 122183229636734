// extracted by mini-css-extract-plugin
export var blogCategory = "BlogPostTile__blogCategory__fhdb_";
export var body = "BlogPostTile__body__av3kz";
export var column = "BlogPostTile__column__j3ZRV";
export var container = "BlogPostTile__container__KzYrU";
export var containerGutter = "BlogPostTile__containerGutter__zpnVe";
export var date = "BlogPostTile__date__O1dPX";
export var details = "BlogPostTile__details__oFszL";
export var featuredImage = "BlogPostTile__featuredImage__Ak6Fg";
export var flex = "BlogPostTile__flex__y276Z";
export var flexColumn = "BlogPostTile__flexColumn__ia9wR";
export var gap1 = "BlogPostTile__gap1__ZtSby";
export var gap2 = "BlogPostTile__gap2__POosM";
export var gap3 = "BlogPostTile__gap3__mEQGh";
export var gap4 = "BlogPostTile__gap4__zPPDB";
export var gap5 = "BlogPostTile__gap5__eDuCB";
export var marginButtom0 = "BlogPostTile__marginButtom0__7j_JV";
export var marginHorizontal0 = "BlogPostTile__marginHorizontal0__sRrZ1";
export var marginTop0 = "BlogPostTile__marginTop0__ojSjl";
export var paddingHorizontal0 = "BlogPostTile__paddingHorizontal0__X4hCC";
export var row = "BlogPostTile__row__vWOcF";
export var title = "BlogPostTile__title__dfb8J";
export var wrapper = "BlogPostTile__wrapper__ZkLJJ";