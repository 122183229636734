// extracted by mini-css-extract-plugin
export var barContainer = "BlogPostTopBar__barContainer__z1Y7k";
export var barWrapper = "BlogPostTopBar__barWrapper__WBhpE";
export var column = "BlogPostTopBar__column__nQTO2";
export var flex = "BlogPostTopBar__flex__Wjbv9";
export var flexColumn = "BlogPostTopBar__flexColumn__Z4oqc";
export var gap1 = "BlogPostTopBar__gap1__PhAyq";
export var gap2 = "BlogPostTopBar__gap2__XMMpG";
export var gap3 = "BlogPostTopBar__gap3__InGbo";
export var gap4 = "BlogPostTopBar__gap4__E8Pg3";
export var gap5 = "BlogPostTopBar__gap5__kejGf";
export var linkContainer = "BlogPostTopBar__linkContainer__q9Xvy";
export var linkItem = "BlogPostTopBar__linkItem__R9FpC";
export var row = "BlogPostTopBar__row__wZ9Yc";
export var underlinedLink = "BlogPostTopBar__underlinedLink__mDjHd";